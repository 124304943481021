// Training Calendar api
export const trainingCalendarBaseUrl = '/tpm/training-calendar/'
export const trainingCalendarList = trainingCalendarBaseUrl + 'list'
export const trainingCalendarToggleStatus = trainingCalendarBaseUrl + 'toggle-status'
export const trainingCalendarStore = trainingCalendarBaseUrl + 'store'
export const trainingCalendarUpdate = trainingCalendarBaseUrl + 'update'
export const trainingCalendarShow = trainingCalendarBaseUrl + 'show'
export const circularPublication = trainingCalendarBaseUrl + 'circular-publication'
export const circularPublicationList = trainingCalendarBaseUrl + 'circular-publication-list'
export const circularPublicationCalender = trainingCalendarBaseUrl + 'circular-publication-calender'
export const trainingCalendarFinalSave = trainingCalendarBaseUrl + 'final-save'

export const trainingPublicationBaseUrl = '/iabm/circular-publications/'
export const circularList = trainingPublicationBaseUrl + 'circular-publication-list'
export const circularApproveList = trainingPublicationBaseUrl + 'circular-publication-list'
export const circularPublicationData = trainingPublicationBaseUrl + 'circular-publication-data'
export const getTrainer = '/external-user/trainer-evaluation/trainer'
export const circularData = '/iabm/circular-publications/' + 'circular-publication-data'

// Trainer Profile api
export const trainerProfileBaseUrl = '/tpm/trainer-profiles/'
export const trainerProfileList = trainerProfileBaseUrl + 'list'
export const trainerProfileStore = trainerProfileBaseUrl + 'store'
export const trainerProfileUpdate = trainerProfileBaseUrl + 'update'
export const trainerProfileShow = trainerProfileBaseUrl + 'show'
export const trainerProfileToggleStatus = trainerProfileBaseUrl + 'toggle-status'
export const trainerProfilePersonalInfo = trainerProfileBaseUrl + 'personal-info'

// Course Managment api
export const courseManagmentBaseUrl = '/tpm/course-management/'
export const courseManagmentList = courseManagmentBaseUrl + 'list'
export const courseManagmentToggleStatus = courseManagmentBaseUrl + 'toggle-status'
export const courseManagmentStore = courseManagmentBaseUrl + 'store'
export const courseManagmentUpdate = courseManagmentBaseUrl + 'update'
export const courseManagmentShow = courseManagmentBaseUrl + 'show'
export const courseManagmentFinalSave = courseManagmentBaseUrl + 'final-save'

// Trainer Sorting api
export const trainerSortingBaseUrl = '/tpm/trainer-sorting/'
export const trainerSortingList = trainerSortingBaseUrl + 'list'
export const trainerSortingStore = trainerSortingBaseUrl + 'store'

// Course Module api
export const courseModuleBaseUrl = '/tpm/course-module/'
export const courseModuleList = courseModuleBaseUrl + 'list'
export const traineeTrainerOtherList = '/iabm/training-applications/other-trainee-trainer-list'
// export const traineeTrainerOtherDetails = '/iabm/training-applications/tto-list'
export const traineeTrainerOtherDetails = '/tpm/training-attendence/list'
export const personalInfoAdmin = '/iabm/personal-infos/personal-admin/'
export const personalInfo = '/iabm/personal-infos/personal-all-data/'
export const trainingTitleModule = courseModuleBaseUrl + 'training-title-module'
export const courseModuleToggleStatus = courseModuleBaseUrl + 'toggle-status'
export const courseModuleStore = courseModuleBaseUrl + 'store'
export const courseModuleUpdate = courseModuleBaseUrl + 'update'
export const courseModuleShow = courseModuleBaseUrl + 'show'
export const courseWiseModule = courseModuleBaseUrl + 'course-wise-module'
export const courseModuleTrainer = '/tim/bill-payment/select-trainer'

// Trainer Selection api
export const trainerSelectionBaseUrl = '/tpm/trainer-selections/'
export const trainerSelectionList = trainerSelectionBaseUrl + 'list'
export const trainerSelectionStore = trainerSelectionBaseUrl + 'store'
export const courseModuleFinalSave = courseModuleBaseUrl + 'final-save'

// Course Schedule api
export const trainingScheduleBaseUrl = '/tpm/training-schedule/'
export const trainingScheduleList = trainingScheduleBaseUrl + 'list'
export const trainingScheduleRoutine = trainingScheduleBaseUrl + 'routine'
export const trainingScheduleCommittee = trainingScheduleBaseUrl + 'committee'
export const trainingModuleWiseTrainer = trainingScheduleBaseUrl + 'circular-wise-trainer'
export const trainingScheduleTrainer = trainingScheduleBaseUrl + 'schedule-trainer'
export const trainingCheckTrainingInfo = trainingScheduleBaseUrl + 'check-training-info'
export const trainingScheduleToggleStatus = trainingScheduleBaseUrl + 'toggle-status'
export const trainingScheduleStore = trainingScheduleBaseUrl + 'store'
export const trainingScheduleUpdate = trainingScheduleBaseUrl + 'update'
export const trainingScheduleShow = trainingScheduleBaseUrl + 'show'
export const trainingScheduleFinalSave = trainingScheduleBaseUrl + 'final-save'
export const trainingScheduleCircularData = trainingScheduleBaseUrl + 'circular-data'
export const trainingScheduleForward = trainingScheduleBaseUrl + 'forward'
export const trainingScheduleForwardComment = trainingScheduleBaseUrl + 'forward-comment'
export const trainingScheduleForwardList = trainingScheduleBaseUrl + 'forward-list'
export const trainingScheduleApproveReturn = trainingScheduleBaseUrl + 'approve-return'

export const trainingApplicationBaseUrl = '/iabm/training-applications/'
export const trainingApplicationAllList = trainingApplicationBaseUrl + 'application-list'
export const trainingApplicationList = trainingApplicationBaseUrl + 'application'
export const trainingApplicationListPaginate = '/iabm/booking/application'

export const attendenceSheetBaseUrl = '/tpm/training-attendence/'
export const attendenceSheetList = attendenceSheetBaseUrl + 'list'
export const trainingTitledata = attendenceSheetBaseUrl + 'training-information'

// Other Selection api
export const otherTrainingApplicationsBaseUrl = '/iabm/other-training-applications/'
export const otherTrainingApplicationsList = otherTrainingApplicationsBaseUrl + 'list'
export const otherTrainingApplicationsStore = otherTrainingApplicationsBaseUrl + 'store'
export const otherTrainingApplicationsExcelStore = otherTrainingApplicationsBaseUrl + 'excel-store'

// Vanue Management api
export const attendanceUploadsList = '/tpm/attendance-uploads/list'
export const attendanceUploadsStore = '/tpm/attendance-uploads/store'
export const attendanceUploadsUpdate = '/tpm/attendance-uploads/update'
export const attendanceUploadsToggleStatus = '/tpm/attendance-uploads/toggle-status'
export const attendanceUploadsDetails = '/tpm/attendance-uploads/detail'
export const attendanceUploadsDestroy = '/tpm/attendance-uploadsdestroy'

// Training Material Info......
const trainMaterial = '/tpm/training-material-info/'
export const trainingMaterialInfoList = trainMaterial + 'list'
export const trainingMaterialInfoStore = trainMaterial + 'store'
export const trainingMaterialInfoInfo = trainMaterial + 'details'
export const trainingMaterialInfoUpdate = trainMaterial + 'update'
export const trainingMaterialInfoToggle = trainMaterial + 'schedule-publish'

// Nothi api
export const nothi = 'nothi/'
export const nothiRoutinSentList = nothi + 'routine-sent-nothi'
